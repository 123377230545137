import 'bootstrap/dist/css/bootstrap.min.css'
import {NewsCategory} from "./components/news-category/NewsCategory";
import {Header} from "./components/header/Header";
import {TopHeader} from "./components/header/TopHeader";
import {TopNews} from "./components/top-news/TopNews";
import {MainNews} from "./components/main/MainNews";
import data from './data.json'
import {Footer} from "./components/footer/Footer";
import {BottomFooter} from "./components/footer/BottomFooter";
import {BackToTopButton} from "./components/BackToTopButton";
import {PianoOffer} from "./components/PianoOffer";
import {AppBar} from "./components/app-management/AppBar";
import {useContext} from "react";
import {EnvContext} from "../../../core/context/envContext";

export const Home = () => {
	const {isProd} = useContext(EnvContext)

	return (
			<>
				<TopHeader/>
				<Header/>
				<TopNews/>
				<PianoOffer/>
				<NewsCategory newsCategory={data.catNews1} categoryIndex={0} sliderSettings={data.sliderSettings}/>
				<NewsCategory newsCategory={data.catNews2} categoryIndex={1} sliderSettings={data.sliderSettings}/>
				<MainNews/>
				<Footer/>
				<BottomFooter/>
				<BackToTopButton/>
				{isProd === false && <AppBar/>}
			</>
	);
}
