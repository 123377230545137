import './ModalContainer.scss'
import {Modal} from "react-bootstrap";

export const ModalContainer = ({state, children}) => (
		<Modal dialogClassName="external-modal" show={state}>
			<Modal.Body>
				{children}
			</Modal.Body>
		</Modal>
)
