import {Col, Container, Row} from "react-bootstrap";

export const Footer = () => (
		<div className="footer">
			<Container fluid>
				<Row>
					<Col lg="3" md="6">
						<div className="footer-widget">
							<h3 className="title">Useful Links</h3>
							<ul>
								<li>
									<button>Pellentesque</button>
								</li>
								<li>
									<button>Aliquam</button>
								</li>
								<li>
									<button>Fusce placerat</button>
								</li>
								<li>
									<button>Nulla hendrerit</button>
								</li>
								<li>
									<button>Maecenas</button>
								</li>
							</ul>
						</div>
					</Col>

					<Col lg="3" md="6">
						<div className="footer-widget">
							<h3 className="title">Quick Links</h3>
							<ul>
								<li>
									<button>Posuere egestas</button>
								</li>
								<li>
									<button>Sollicitudin</button>
								</li>
								<li>
									<button>Luctus non</button>
								</li>
								<li>
									<button>Duis tincidunt</button>
								</li>
								<li>
									<button>Elementum</button>
								</li>
							</ul>
						</div>
					</Col>

					<Col lg="3" md="6">
						<div className="footer-widget">
							<h3 className="title">Get in Touch</h3>
							<div className="contact-info">
								<p><i className="fa fa-map-marker"></i>123 Terry Lane, New York, USA</p>
								<p><i className="fa fa-envelope"></i>email@example.com</p>
								<p><i className="fa fa-phone"></i>+123-456-7890</p>
								<div className="social">
									<button className="link"><i className="fab fa-twitter"></i></button>
									<button className="link"><i className="fab fa-facebook"></i></button>
									<button className="link"><i className="fab fa-linkedin"></i></button>
									<button className="link"><i className="fab fa-instagram"></i></button>
									<button className="link"><i className="fab fa-youtube"></i></button>
								</div>
							</div>
						</div>
					</Col>

					<Col lg="3" md="6">
						<div className="footer-widget">
							<h3 className="title">Newsletter</h3>
							<div className="newsletter">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed porta dui. Class aptent
									taciti sociosqu ad litora torquent per conubia nostra inceptos
								</p>
								<form>
									<input className="form-control" type="email" placeholder="Your email here"/>
									<button className="btn">Submit</button>
								</form>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
)
