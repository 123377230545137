import axios from "axios";
import {StoredAppUtils} from "../utils/storedAppUtils";

const http = axios.create()
http.interceptors.response.use(
		response => {
			console.log(response)
			// if (response.status !== 200)
			return response.data
		},
		error => {
			console.log(error)
			return Promise.reject(error.data || error.error || error)
		}
)

export class BaseApi {

	static isProd = () => http.get('/api/isProd')

	static getAid = () => http.get('/api/aid')

	static getApp = (aid) => http.get(`/api/${aid}`)

	static addApp = (app) => http.post('/api/app', app)

	static getApps = () => http.get('/api/apps')

	static deleteApp = (aid) => http.delete(`/api/app/${aid}`)
}

export class ProdApi extends BaseApi {
	subscriptions = (state, data) => http.post('/api/subscriptions' + (state ? `?state=${state}` : ''), data)

	subscribe = (data) => http.post('/api/subscribe', data)

	cancel = (data) => http.post('/api/cancel', data)

	event = (eventName, data) => http.post(`/api/${eventName}`, data)
}

export class LocalApi extends BaseApi {
	subscriptions = (state, data) => http.post(`/api/${StoredAppUtils.getStoredAid()}/subscriptions${state ? `?state=${state}` : ''}`, data)

	subscribe = (data) => http.post(`/api/${StoredAppUtils.getStoredAid()}/subscribe`, data)

	cancel = (data) => http.post(`/api/${StoredAppUtils.getStoredAid()}/cancel`, data)

	event = (eventName, data) => http.post(`/api/${StoredAppUtils.getStoredAid()}/${eventName}`, data)
}
