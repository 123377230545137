import './Account.scss'
import {Col, Row} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {TpService} from "../../../core/service/TpService";
import 'simplebar-react/dist/simplebar.min.css';
import {Subscription} from "./components/subscription/Subscription";
import {NoSubscriptions} from "./components/NoSubscriptions";
import {AccountBar} from "./components/account-bar/AccountBar";
import {ModalHeader} from "../modal/components/header/ModalHeader";
import {CustomScrollbar} from "../../../core/components/CustomScrollbar";
import {EnvContext} from "../../../core/context/envContext";

export const Account = () => {
	const {api} = useContext(EnvContext)
	const [subscriptionState, setSubscriptionState] = useState('active')
	const [subscriptionsAndTerms, setSubscriptionsAndTerms] = useState({})

	const initSubscriptions = () => {
		api.subscriptions(subscriptionState, {url: TpService.apiUrl(), user_token: TpService.userToken()})
				.then(res => {
					const {subscriptions, terms} = res || {}
					setSubscriptionsAndTerms({subscriptions, terms})
				})
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initSubscriptions, [])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initSubscriptions, [subscriptionState])

	return <div className="account">
		<Row>
			<Col><ModalHeader title="News 24 user account"/></Col>
		</Row>
		<Row>
			<Col>
				<AccountBar
						subscriptions={subscriptionsAndTerms.subscriptions}
						subscriptionState={subscriptionState}
						setSubscriptionState={setSubscriptionState}
				/>
			</Col>
		</Row>
		{subscriptionsAndTerms.subscriptions?.length
				? <CustomScrollbar maxHeight={472} minHeight={472}>
					<div className="account-subscriptions">
						{subscriptionsAndTerms.subscriptions.map((info, i) => (
										<Subscription
												key={i}
												subInfo={info}
												upgradeTerm={subscriptionsAndTerms.terms.find(t => t.externalTermId === info.upgrade)}
												reload={initSubscriptions}
										/>
								)
						)}
					</div>
				</CustomScrollbar>
				: <NoSubscriptions/>
		}
	</div>
}
