import {BaseApi} from '../api/api'
import 'bootstrap'

var tp = window['tp'] || []

function getProdUrl() {
	return BaseApi.getAid().then(res => {
		tp.push(['setAid', res.aid])
		tp.push(['setSandbox', false])
		return '//cdn.piano.io/'
	})
}

function getLocalUrl(aid) {
	return BaseApi.getApp(aid).then(app => {
		if (!app) return alert('Cannot fetch app')
		tp.push(['setAid', aid])
		if (app.url[app.url.length - 1] !== '/') app.url += '/'
		tp.push(['setEndpoint', `${app.url}api/v3`])
		if (app.c2Url) {
			tp.push(['setComposerHost', app.c2Url])
		} else if (app.url.includes('.qa.piano.dev')) {
			tp.push(['setComposerHost', [app.url.slice(0, 8), 'c2-', app.url.slice(8)].join('')])
		}
		return app.url
	})
}

export class TpService {
	static onLogin
	static onLogout
	static afterInit

	static openCheckout
	static openAccount

	static init(env) {
		tp.push(['setPianoIdUrl', '/'])

		tp.push(['setTags', ['linked_term_app']])

		tp.push(['setUsePianoIdUserProvider', true])
		tp.push(['addHandler', 'loginSuccess', () => {
			this.onLogin && this.onLogin()
		}])
		tp.push(['addHandler', 'logout', () => {
			this.onLogout && this.onLogout()
		}])

		tp.push(['setLinkTermSelected', (session, data) => {
			const linkTermSignedData = data.linkTermSignedData;
			(linkTermSignedData ? window.tp.verifySignature(linkTermSignedData.payload, linkTermSignedData.signature, linkTermSignedData.publicKey) : Promise.resolve(true))
					.then((valid) => {
						if (!valid) {
							alert('Payload invalid')
							return
						}

						this.openExternalCheckout(
								{
									sessionId: session,
									term: data
								}
						)
					}).catch(function (err) {
						alert('Verification failed ' + err)
					})
		}])

		tp.push(['addHandler', 'checkoutCustomEvent', (event) => {
			if (event.eventName === 'manage-subscriptions') {
				this.openAccountManagement()
			}
		}]);

		tp.push(['init', () => {
			window.tp.experience.init();
			this.afterInit && this.afterInit()
		}]);

		env.isProd && tp.push(["setCxenseSiteId", "5858466526134080427"]);


		(env.isProd ? getProdUrl() : getLocalUrl(env.app.aid)).then(url => {
			(function () {
				window.tp = tp;

				var a = document.createElement('script')
				a.type = 'text/javascript'
				a.async = true
				a.src = `${url}api/tinypass.min.js`
				var b = document.getElementsByTagName('script')[0]
				b.parentNode.insertBefore(a, b)
			})()
		})
	}

	static openAccountManagement() {
		this.openAccount && this.openAccount()
	}

	static openExternalCheckout(params) {
		this.openCheckout && this.openCheckout(params)
	}

	static userToken() {
		return window.tp && (window.tp.externalJWT || (window.tp.pianoId && window.tp.pianoId.getToken()))
	}

	static apiUrl() {
		return (window.tp && window.tp.getApiEndpoint && window.tp.getApiEndpoint()) || ''
	}

	static executeExperience() {
		window.tp && window.tp.experience && window.tp.experience.execute()
	}

	static loggedIn() {
		return window.tp && window.tp.user && window.tp.user.isUserValid()
	}

	static getEmail() {
		return window.tp.pianoId.getUser().email
	}

	static login() {
		window.tp.user.showLogin()
	}

	static logout() {
		window.tp.user.logout()
	}
}