import Moment from "react-moment";

export const SubscriptionState = ({info, upgradeTerm}) => {
	if (info.state === 'Active') {
		return <div>
			<span>Subscription {(info.subscription.auto_renew ? 'renews' : 'expires')} on </span>
			<Moment unix format="MMM DD, YYYY">{info.subscription.valid_to}</Moment>
			<span>{info.subscription.auto_renew ? ' with Visa *1111' : ''}</span>
		</div>
	} else if (info.state === 'Upgraded' && upgradeTerm) {
		return <div>Upgraded to {upgradeTerm.name}</div>
	} else {
		return <div>{info.state}</div>
	}
}
