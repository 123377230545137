import './Header.scss'
import {Button} from "react-bootstrap";
import {useContext} from "react";
import {ModalContext} from "../../../../../core/context/modalContext";

export const Header = () => {
	const modal = useContext(ModalContext)

	return (
			<div className="checkout-header">
				<Button onClick={() => modal.close()} variant="link">
					<img src="img/close.svg" alt="close"/>
				</Button>
			</div>
	)
}
