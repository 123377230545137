import {Button, Col, Row} from "react-bootstrap";

export const AppRow = ({app, selectApp, deleteApp}) => {

	return (
			<Row className="app-list-row pt-1 pb-1">
				<Col md="10" className="app-select" onClick={() => selectApp(app)}>
					<div>{app.aid}</div>
					<a href={app.url}>{app.url}</a>
				</Col>
				<Col md="2">
					<div className="d-flex h-100 align-items-center justify-content-end">
						<Button className="button-link text-end" onClick={e => deleteApp(e, app)}>
							Delete
						</Button>
					</div>
				</Col>
			</Row>
	)
}
