import './App.css';
import {Home} from "./pages/publisher/home/Home";
import {useEffect, useState} from "react";
import {BaseApi, LocalApi, ProdApi} from "./core/api/api";
import {EnvContext} from "./core/context/envContext";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {ModalContext} from "./core/context/modalContext";
import {NotificationContext} from "./core/context/notificationContext";
import {Alert} from "react-bootstrap";
import {ModalContainer} from "./pages/publisher/modal/ModalContainer";
import {AppManagement} from "./pages/publisher/home/components/app-management/AppManagement";
import {StoredAppUtils} from "./core/utils/storedAppUtils";

const router = createBrowserRouter([
	{path: '/', element: <Home/>}
])

const App = () => {
	const [isProd, setIsProd] = useState(null)
	const [app, setApp] = useState(null)
	const [api, setApi] = useState(null)
	const [modal, setModal] = useState({state: false, children: null})
	const [notification, setNotification] = useState({state: false, variant: 'success', content: ''})

	const modalContextValue = {
		open: (children) => {
			setModal({state: true, children: children})
		},
		close: () => {
			setModal({state: false, children: null})
		}
	}

	const notificationContextValue = {
		show: (variant, content) => {
			setNotification({state: true, variant: variant, content: content})
			setTimeout(() => setNotification({...notification, state: false}), 5000)
		}
	}

	const init = () => {
		console.log('isProd', isProd)
		if (isProd === false) {
			setApi(new LocalApi())
			const storedApp = StoredAppUtils.getStoredApp()
			if (!storedApp) {
				setModal({state: true, children: <AppManagement/>})
				return
			}
			setApp(storedApp)
		} else if (isProd === true) {
			StoredAppUtils.clearStoredApp()
			setApi(new ProdApi())
		}
	}

	const storeApp = (app) => {
		StoredAppUtils.storeApp(app)
		window.location.reload()
	}

	useEffect(() => {
		BaseApi.isProd().then(setIsProd)
	}, [])

	useEffect(init, [isProd])

	return (
			<div className="App">
				{isProd !== null &&
						<EnvContext.Provider value={{isProd, app, api, setApp: storeApp}}>
							<NotificationContext.Provider value={notificationContextValue}>
								<ModalContext.Provider value={modalContextValue}>
									{(isProd === true || app) && <RouterProvider router={router}/>}
									<ModalContainer state={modal.state}>{modal.children}</ModalContainer>
								</ModalContext.Provider>
							</NotificationContext.Provider>
						</EnvContext.Provider>
				}
				{notification.state &&
						<Alert className="notification" variant={notification.variant} dismissible>{notification.content}</Alert>}
			</div>
	);
};

export default App;
