import {useContext, useEffect} from "react";
import {EnvContext} from "../../../../core/context/envContext";
import {TpService} from "../../../../core/service/TpService";
import {ModalContext} from "../../../../core/context/modalContext";
import {Checkout} from "../../checkout/Checkout";

export const PianoOffer = () => {
	const env = useContext(EnvContext)
	const modal = useContext(ModalContext)

	const openCheckout = (params) => {
		modal.open(<Checkout {...params}/>)
	}

	useEffect(() => {
		TpService.openCheckout = openCheckout
		TpService.init(env)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (<div id="for-offer" className="row"></div>)
}
