import './AppManagement.scss'
import {BaseApi} from "../../../../../core/api/api";
import {useContext, useEffect, useState} from "react";
import {ModalHeader} from "../../../modal/components/header/ModalHeader";
import {Button, Col, Container, Row} from "react-bootstrap";
import {EnvContext} from "../../../../../core/context/envContext";
import {ModalContext} from "../../../../../core/context/modalContext";
import {CustomScrollbar} from "../../../../../core/components/CustomScrollbar";
import {AddAppForm} from "./AddAppForm";
import {AppRow} from "./AppRow";
import {NotificationContext} from "../../../../../core/context/notificationContext";

export const AppManagement = () => {
	const {setApp} = useContext(EnvContext)
	const modal = useContext(ModalContext)
	const notification = useContext(NotificationContext)
	const [apps, setApps] = useState([])
	const [editMode, setEditMode] = useState(false)

	useEffect(() => {
		initApps()
	}, [])

	const initApps = () => {
		BaseApi.getApps().then(res => {
			setApps(res)
		})
	}

	const selectApp = (app) => {
		setApp(app)
		modal.close()
	}

	const deleteApp = (e, app) => {
		e.preventDefault()
		if (window.confirm('Delete app ' + app.aid + "?")) {
			BaseApi.deleteApp(app.aid).then(
					() => {
						initApps()
						notification.show('success', 'Success')
					},
					err => notification.show('danger', err.response?.data || err.message)
			)
		}
	}

	const onAppAdded = (app) => {
		setApps([...apps, app])
		setEditMode(false)
	}

	return (
			<div className="apps">
				<ModalHeader title="Apps"/>
				{!editMode ?
						<>
							<Container fluid>
								<Row className="app-management-bar">
									<Col md="10">
										<div>Apps ({apps.length})</div>
									</Col>
									<Col md="2">
										<div className="d-flex h-100 align-items-center justify-content-end">
											<Button className="button-link text-end" onClick={() => setEditMode(true)}>Add</Button>
										</div>
									</Col>
								</Row>
							</Container>
							<CustomScrollbar maxHeight={350} minHeight={350}>
								<Container fluid>
									<div className="app-management">
										{apps.length && apps.map((app, i) => (
												<AppRow key={i} app={app} selectApp={selectApp} deleteApp={deleteApp}/>
										))}
									</div>
								</Container>
							</CustomScrollbar>
						</>
						: <div className="app-management"><AddAppForm onAppAdded={onAppAdded} onCancel={() => setEditMode(false)}/></div>
				}
			</div>
	)
}
