import {Button, Col, Container, Row} from "react-bootstrap";

const topNews = [
	'Lorem ipsum dolor sit amet adipiscing elit',
	'Integer faucibus pharetra odio',
	'Nulla vitae pharetra ligula',
	'Ut ac euismod tellus a blandit',
	'Cras ac egestas sem nec euismod'
]

const TopNewsElement = ({text, index}) => (
		<div className="tn-img">
			<img src={`img/top-news-${index + 1}.jpg`} alt="top-news"/>
			<div className="tn-content">
				<div className="tn-content-inner">
					<Button variant="link" className="tn-date" href=""><i className="far fa-clock"></i>05-Feb-2020</Button>
					<Button variant="link" className="tn-title" href="">{text}</Button>
				</div>
			</div>
		</div>
)

export const TopNews = () => (
		<div className="top-news">
			<Container fluid>
				<Row>
					<Col md="6" className="tn-left">
						<TopNewsElement text={topNews[0]} index={0}/>
					</Col>
					<Col md="6" className="tn-right">
						<Row>
							{topNews.slice(1).map((news, i) => (
									<Col key={i} md="6">
										<TopNewsElement text={news} index={i + 1}/>
									</Col>
							))}
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
)
