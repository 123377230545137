import {useContext, useEffect, useState} from "react";
import {Container, Dropdown, Nav, Navbar, NavItem, NavLink} from "react-bootstrap";
import {TpService} from "../../../../../core/service/TpService";
import {ModalContext} from "../../../../../core/context/modalContext";
import {Account} from "../../../account/Account";

export const Header = () => {
	const modal = useContext(ModalContext)

	const [sticky, setSticky] = useState(false)
	const [loggedIn, setLoggedId] = useState(false)



	useEffect(() => {
		TpService.onLogin = () => setLoggedId(true)
		TpService.onLogout = () => setLoggedId(false)
		TpService.afterInit = () => setLoggedId(TpService.loggedIn())
		TpService.openAccount = openAccountManagement
		window.addEventListener("scroll", () => setSticky(document.body.scrollTop > 90 || document.documentElement.scrollTop > 90))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {TpService.executeExperience()}, [loggedIn])

	const openAccountManagement = () => modal.open(<Account/>)

	return (
			<div className={"header" + (sticky ? " header-sticky" : "")}>
				<Container>
					<Navbar bg="dark" expand="md" data-bs-theme="dark" className="navbar-dark">
						<Container>
							<Navbar.Brand href="#">MENU</Navbar.Brand>
							<Navbar.Toggle aria-controls="navbarCollapse"/>
							<Navbar.Collapse id="navbarCollapse">
								<Nav className="m-auto">
									<Nav.Link href="index.html" active>Home</Nav.Link>
									<Nav.Link href="#">Sports</Nav.Link>
									<Nav.Link href="#">Tech</Nav.Link>
									<Nav.Link href="#">Fashion</Nav.Link>
									<Dropdown as={NavItem} data-bs-theme="light">
										<Dropdown.Toggle as={NavLink}>{loggedIn ? TpService.getEmail() : "Account"}</Dropdown.Toggle>
										<Dropdown.Menu className="account-menu">
											{loggedIn
													? (<>
														<Dropdown.Item onClick={openAccountManagement}>News 24 Account Management</Dropdown.Item>
														<Dropdown.Item onClick={TpService.logout}>Log out</Dropdown.Item>
													</>)
													: (<Dropdown.Item onClick={TpService.login}>Log in</Dropdown.Item>)}
										</Dropdown.Menu>
									</Dropdown>
								</Nav>
							</Navbar.Collapse>
						</Container>
					</Navbar>
				</Container>
			</div>
	);
}
