import {Col, Container, Row} from "react-bootstrap";

const socials = ['twitter', 'facebook', 'linkedin', 'instagram', 'youtube']

export const TopHeader = () => (
		<div className="top-header">
			<Container>
				<Row className="align-items-center">
					<Col lg="3" md="4">
						<div className="logo">
							<button className="link">
								<img src="img/logo.png" alt="Logo"/>
							</button>
						</div>
					</Col>
					<Col lg="6" md="4">
						<div className="search">
							<input type="text" placeholder="Search"/>
							<button><i className="fa fa-search"></i></button>
						</div>
					</Col>
					<Col lg="3" md="4">
						<div className="social">
							{socials.map((social, i) => (
									<button key={i} className="link"><i className={`fab fa-${social}`}></i></button>
							))}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
)
