import "./Subscription.scss"
import {Col, Row} from "react-bootstrap";
import {SubscriptionState} from "./SubscriptionState";
import {ManageDropdown} from "./ManageDropdown";
import {TpService} from "../../../../../core/service/TpService";
import {useContext} from "react";
import {NotificationContext} from "../../../../../core/context/notificationContext";
import {EnvContext} from "../../../../../core/context/envContext";

export const Subscription = ({subInfo, upgradeTerm, reload}) => {
	const notification = useContext(NotificationContext)
	const {api} = useContext(EnvContext)

	const sendEvent = (eventName, event, info) => {
		api.event(eventName, {
			url: TpService.apiUrl(),
			user_token: TpService.userToken(),
			sub_id: info.subId,
			term: eventName === 'upgrade' ? upgradeTerm : null
		})
				.then(res => {
					if (res.event) {
						console.log(`"${event.action}" event`)
						console.log('%c ' + JSON.stringify(res.event), 'color: #2364cd')
					}
					if (res.message === 'success') {
						notification.show('success', `${event.success}. See console for details`)
						reload()
					} else {
						notification.show('error', res.message)
					}
				})
	}

	return (
			<Row>
				<Col>
					<div className="account-subscription">
						<div className="account-subscription-main">
							<img src="img/sharp.svg" className="sharp" alt="sharp"/>
							<div className="account-subscription-name">{subInfo.name}</div>
						</div>
						<div className="account-subscription-caption">
							<SubscriptionState info={subInfo} upgradeTerm={upgradeTerm}/>
						</div>
					</div>
				</Col>
				<Col>
					{subInfo.state === 'Active' &&
							<ManageDropdown subInfo={subInfo} upgradeTerm={upgradeTerm} sendEvent={sendEvent}/>
					}
				</Col>
			</Row>
	);
}
