import {Button, Form} from "react-bootstrap";
import {useContext, useState} from "react";
import {NotificationContext} from "../../../../../core/context/notificationContext";
import {BaseApi} from "../../../../../core/api/api";

export const AddAppForm = ({onAppAdded, onCancel}) => {
	const [values, setValues] = useState({});
	const notification = useContext(NotificationContext)

	const onFormChange = (e) => {
		const name = e.target.name;
		const value = e.target.value;
		setValues({...values, [name]: value});
		console.log(name, value);
	};

	const addApp = (e) => {
		e.preventDefault() && e.persist()
		BaseApi.addApp(values).then(
				res => {
					notification.show('success', 'App ' + values.aid + ' added')
					onAppAdded(res)
				},
				error => {
					notification.show('danger', error.response?.data || error.message)
				}
		)
	}

	return (
			<Form onSubmit={e => addApp(e)}>
				<Form.Group>
					<Form.Label>AID</Form.Label>
					<Form.Control name="aid" type="text" required placeholder="Application ID" onChange={onFormChange}/>
				</Form.Group>

				<Form.Group>
					<Form.Label>API token</Form.Label>
					<Form.Control name="apiToken" type="text" required placeholder="api_token" onChange={onFormChange}/>
				</Form.Group>

				<Form.Group>
					<Form.Label>Instance URL</Form.Label>
					<Form.Control
							name="url"
							type="url"
							required
							placeholder="https://develop.qa.piano.io/"
							onChange={onFormChange}
					/>
				</Form.Group>

				<Form.Group>
					<Form.Label>C2 Instance URL (optional)</Form.Label>
					<Form.Control
							name="c2Url"
							type="url"
							placeholder="https://c2-develops2489.qa.piano.dev/"
							onChange={onFormChange}
					/>
				</Form.Group>

				<Button type="submit">Add</Button>
				<Button className="ms-2" onClick={onCancel}>Cancel</Button>
			</Form>
	)
}
