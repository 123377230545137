import Slider from "react-slick";
import {NewsCategoryItem} from "./NewsCategoryItem";
import {Col, Container, Row} from "react-bootstrap";

export const NewsCategory = ({newsCategory, categoryIndex, sliderSettings}) => {
	let imgIndex = 0

	return (
			<div className="cat-news">
				<Container fluid>
					<Row>
						{Object.keys(newsCategory).map((title, i) => {
							return (
									<Col key={i} md="6">
										<h2><i className="fas fa-align-justify"></i>{title}</h2>
										<Slider className="row cn-slider" {...sliderSettings}>
											{newsCategory[title].map((text, j) => {
												return <NewsCategoryItem key={j} text={text} categoryIndex={categoryIndex} newsIndex={imgIndex++}/>
											})}
										</Slider>
									</Col>
							)
						})}
					</Row>
				</Container>
			</div>
	);
}
