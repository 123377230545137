import './Bill.scss'
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {useContext, useState} from "react";
import {TpService} from "../../../../../core/service/TpService";
import {ModalContext} from "../../../../../core/context/modalContext";
import {NotificationContext} from "../../../../../core/context/notificationContext";
import {PriceUtils} from "../../../../../core/utils/priceUtils";
import {EnvContext} from "../../../../../core/context/envContext";

export const Bill = ({sessionId, term}) => {
	const modal = useContext(ModalContext)
	const notification = useContext(NotificationContext)
	const {api} = useContext(EnvContext)
	const [spinner, setSpinner] = useState(false)

	const submit = (e) => {
		setSpinner(true)
		e && e.preventDefault() && e.preventDefault()
		api.event('subscribe', {
			url: TpService.apiUrl(),
			user_token: TpService.userToken(),
			term: term,
			session_id: sessionId
		})
				.then(res => {
					if (res.event) {
						console.log('"create_subscription" event')
						console.log('%c ' + JSON.stringify(res.event), 'color: #2364cd')
					}
					if (res.message === 'success') {
						notification.show('success', 'Subscription successful! Check console for details')
						modal.close()
					} else {
						notification.show('danger', res.message || res.error)
					}
					setSpinner(false)
				})
	}

	return (
			<div className="bill">
				<div className="bill-container">
					<button disabled={spinner} className="gpay" onClick={submit}>
						<img src="img/gpay.svg" alt="gpay"/>
					</button>
					<div className="pay-with-card">
						<div className="divider"/>
						<div className="pay-with-card-text">Or pay with card</div>
						<div className="divider"/>
					</div>
					<Form className="form" onSubmit={submit}>
						<Form.Group>
							<Form.Label>Email</Form.Label>
							<Form.Control type="email" placeholder="Email"/>
						</Form.Group>

						<Form.Group>
							<Form.Label>Card information</Form.Label>
							<Row className="card-row">
								<Col>
									<Form.Control
											className="card-number" maxLength="16" type="text" placeholder="1234 1234 1234 1234"
									/>
								</Col>
							</Row>
							<Row className="card-row">
								<Col>
									<Form.Control className="card-date" maxLength="5" type="text" placeholder="MM/YY"/>
								</Col>
								<Col>
									<Form.Control className="card-cvc" maxLength="5" type="text" placeholder="CVC"/>
								</Col>
							</Row>
						</Form.Group>

						<Form.Group>
							<Form.Label>Name of card</Form.Label>
							<Form.Control type="text" placeholder="Name of card"/>
						</Form.Group>

						<Form.Group>
							<Form.Label>Country or region</Form.Label>
							<Form.Select className="country-select" defaultValue="United States">
								<option>United States</option>
							</Form.Select>
							<Form.Control className="country-zip" type="text" placeholder="ZIP"/>
						</Form.Group>

						<Button disabled={spinner} className="form-submit" type="submit">
							{spinner ? <Spinner as="span" size="sm"/> : `Pay ${term.price ? PriceUtils.priceString(term.trial || term.price) : ''}`}
						</Button>
					</Form>
				</div>
			</div>
	)
}
