export class StoredAppUtils {
	static getStoredApp() {
		const app = localStorage.getItem('app')
		return app && JSON.parse(app)
	}

	static storeApp(app) {
		localStorage.setItem('app', JSON.stringify(app))
	}

	static clearStoredApp() {
		localStorage.clear()
	}

	static getStoredAid() {
		const app = this.getStoredApp()
		return (app && app.aid) || null
	}
}
