import './ModalHeader.scss'
import {Button} from "react-bootstrap";
import {useContext} from "react";
import {ModalContext} from "../../../../../core/context/modalContext";

export const ModalHeader = ({title}) => {
	const modal = useContext(ModalContext)

  return (
		  <div className="modal-custom-header">
			  <div className="modal-custom-header-title">{title}</div>
			  <Button onClick={modal.close} variant="link">
				  <img src="img/close.svg" alt="close"/>
			  </Button>
		  </div>
  )
}
