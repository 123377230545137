import {Accordion} from "react-bootstrap";
import {useContext} from "react";
import {EnvContext} from "../../../../../core/context/envContext";
import {ModalContext} from "../../../../../core/context/modalContext";
import {AppManagement} from "./AppManagement";

export const AppBar = () => {
	const env = useContext(EnvContext)
	const modal = useContext(ModalContext)

	const manage = () => modal.open(<AppManagement/>)

	return (env && env.app)
			? (
					<div className="app-bar">
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>Application</Accordion.Header>
								<Accordion.Body>
									<div className="d-flex mb-2"><strong style={{width: 50}}>App: </strong>{env.app.aid}</div>
									<div className="d-flex mt-2 mb-2"><strong style={{width: 50}}>Url: </strong>{env.app.url}</div>
									<button onClick={manage}>Manage</button>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
			)
			: <></>
}
