export const NewsCategoryItem = ({categoryIndex, newsIndex, text}) => (
			<div className="cn-img">
				<img src={`img/cat-news-${categoryIndex}-${newsIndex}.jpg`} alt="cat-news"/>
				<div className="cn-content">
					<div className="cn-content-inner">
						<button className="cn-date"><i className="far fa-clock"></i>05-Feb-2020</button>
						<button className="cn-title">{text}</button>
					</div>
				</div>
		</div>
)
