import './AccountBar.scss'
import {Button, Dropdown} from "react-bootstrap";

const states = {
	active: 'Active',
	canceled: 'Canceled',
	expired: 'Expired',
	upgraded: 'Upgraded'
}

export const AccountBar = ({subscriptions, subscriptionState, setSubscriptionState}) => {
  return (
		  <div className="account-bar">
			  <div className="account-bar-title">
				  Subscriptions {subscriptions?.length ? `(${subscriptions.length})` : ''}
			  </div>
			  <Dropdown>
				  <Dropdown.Toggle as={Button} variant="link" className="account-bar-toggle">
					  {states[subscriptionState]}
					  <img width="30px" height="30px" src="img/chevron.svg" alt="chevron"/>
				  </Dropdown.Toggle>

				  <Dropdown.Menu align="end">
					  {Object.keys(states).map((state, i) =>
							  <Dropdown.Item key={i} onClick={() => setSubscriptionState(state)}>{states[state]}</Dropdown.Item>
					  )}
				  </Dropdown.Menu>
			  </Dropdown>
		  </div>
  )
}
