import {Col, Row} from "react-bootstrap";

export const News = ({title, main, news, img}) => (
		<Col md="12">
			<h2><i className="fas fa-align-justify"></i>{title}</h2>
			<Row>
				<Col lg="6">
					<div className="mn-img">
						<img src={`img/${img}`} alt="news"/>
					</div>
					<div className="mn-content">
						<button className="mn-title">{main}</button>
						<button className="mn-date"><i className="far fa-clock"></i>05-Feb-2020</button>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed porta dui.Class aptent taciti
							sociosqu ad litora torquent per conubia nostra inceptos...</p>
					</div>
				</Col>
				<Col lg="6">
					{news.map((text, i) => (
							<div key={i} className="mn-list">
								<div className="mn-img">
									<img src={`img/${img}`} alt="news"/>
								</div>
								<div className="mn-content">
									<button className="mn-title" href="">{text}</button>
									<button className="mn-date" href=""><i className="far fa-clock"></i>05-Feb-2020</button>
								</div>
							</div>
					))}
				</Col>
			</Row>
		</Col>
)
