import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Term} from "./components/term/Term";
import {Bill} from "./components/bill/Bill";
import {Header} from "./components/header/Header";
import {PriceUtils} from "../../../core/utils/priceUtils";

export const Checkout = (props) => {
	const [term, setTerm] = useState(props.term)

	useEffect(() => {
		const data = term.linkedTermData || JSON.parse(term.linkTermSignedData.payload)
		const price = data.customData.price && {
			...data.customData.price,
			currency: PriceUtils.currencies[data.customData.price.currency] || data.customData.price.currency
		}
		const trial = data.customData.trial && {
			...data.customData.trial,
			currency: price?.currency
		}

		setTerm({
			...term,
			...data,
			price: price,
			trial: trial,
			period: data.customData.period
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
			<div className="checkout">
				<Header/>
				<Container>
					<Row>
						<Col><Term term={term}/></Col>
						<Col><Bill term={term} sessionId={props.sessionId}/></Col>
					</Row>
				</Container>
			</div>
	)
}
