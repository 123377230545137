import {News} from "./News";
import data from './data.json'
import {Col, Container, Row} from "react-bootstrap";

export const MainNews = () => {
	return (
			<div className="main-news">
				<Container fluid>
					<Row>
						<Col md="8">
							<Row>
								<News {...data.latestNews}/>
								<News {...data.popularNews}/>
							</Row>
						</Col>

						<Col md="4">
							<div className="sidebar">
								<div className="sidebar-widget">
									<h2><i className="fas fa-align-justify"></i>Category</h2>
									<div className="category">
										<ul className="fa-ul">
											{data.tags.map((tag, i) => (
													<li key={i}>
														<span className="fa-li"><i className="far fa-arrow-alt-circle-right"></i></span>
														<button>{tag}</button>
													</li>
											))}
										</ul>
									</div>
								</div>

								<div className="sidebar-widget">
									<h2><i className="fas fa-align-justify"></i>Tags</h2>
									<div className="tags">
										{Array.from(
												{length: 2},
												(_, i) => data.tags.map((tag, j) => (<button key={(i + 1) * j} className="link">{tag}</button>))
										)}
									</div>
								</div>

								<div className="sidebar-widget">
									<h2><i className="fas fa-align-justify"></i>Ads 1 column</h2>
									<div className="image">
										<button><img src="img/adds-1.jpg" alt="news"/></button>
									</div>
								</div>

								<div className="sidebar-widget">
									<h2><i className="fas fa-align-justify"></i>Ads 2 column</h2>
									<div className="image">
										<Row>
											<Col sm="6">
												<button><img src="img/adds-2.jpg" alt="news"/></button>
											</Col>
											<Col sm="6">
												<button><img src="img/adds-2.jpg" alt="news"/></button>
											</Col>
										</Row>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
	)
}
