import {useEffect, useState} from "react";

export const BackToTopButton = () => {
	const [show, setShow] = useState(false)

	const showOnScroll = () => setShow(document.body.scrollTop > 100 || document.documentElement.scrollTop > 100)

	const backToTop = () => {
		document.body.scrollTop = document.documentElement.scrollTop = 0
	}

	useEffect(() => {
		window.addEventListener("scroll", showOnScroll)
		showOnScroll()
	}, [])

	return (<button
			onClick={backToTop}
			className={`back-to-top ${show ? "back-to-top--show" : ""}`}
	>
		<i className="fa fa-chevron-up"></i>
	</button>)
}
