import Scrollbars from "react-custom-scrollbars-2";

const trackVerticalStyle = {
	position: 'absolute',
	width: 6,
	right: 6,
	bottom: 2,
	top: 2,
	borderRadius: 3
}

export const CustomScrollbar = ({maxHeight, minHeight, children}) => (
		<Scrollbars
				style={{maxHeight: maxHeight || 300, minHeight: minHeight || 300}}
				renderThumbVertical={({style, ...props}) =>
						<div style={{...style, backgroundColor: '#EDAE81'}}{...props}/>
				}
				renderTrackVertical={({style, ...props}) =>
						<div style={{...style, ...trackVerticalStyle}} {...props}/>
				}
		>
			{children}
		</Scrollbars>
)
