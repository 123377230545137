import {Button, Dropdown} from "react-bootstrap";

const events = {
	cancel: {action: 'delete_subscription', success: 'Subscription canceled'},
	renew: {action: 'update_subscription', success: 'Subscription renewed'},
	autoRenew: {action: 'update_subscription', success: 'Subscription auto renew updated'},
	upgrade: {action: 'create_subscription', success: 'Subscription upgraded'}
}

export const ManageDropdown = ({subInfo, upgradeTerm, sendEvent}) => <Dropdown>
	<Dropdown.Toggle as={Button} variant="link">
		Manage
		<img width="30px" height="30px" src="img/chevron.svg" alt="chevron"/>
	</Dropdown.Toggle>

	<Dropdown.Menu>
		<Dropdown.Item onClick={() => sendEvent('renew', events.renew, subInfo)}>
			Renew
		</Dropdown.Item>
		<Dropdown.Item onClick={() => sendEvent('cancel', events.cancel, subInfo)}>
			Cancel
		</Dropdown.Item>
		<Dropdown.Item onClick={() => sendEvent('autoRenew', events.autoRenew, subInfo)}>
			{(subInfo.subscription.auto_renew && 'Disable') || 'Enable'} auto renew
		</Dropdown.Item>
		{subInfo.upgrade && upgradeTerm && (
				<Dropdown.Item onClick={() => sendEvent('upgrade', events.upgrade, subInfo)}>
					Upgrade to {upgradeTerm.name}
				</Dropdown.Item>
		)}
	</Dropdown.Menu>
</Dropdown>
