import './Term.scss'
import {PriceUtils} from "../../../../../core/utils/priceUtils";

const periods = {
	'1 month': 'monthly',
	'annual': 'yearly'
}

const nextPeriods = (term) => {
	return 'Then ' + PriceUtils.priceString(term.price) + ' ' + (periods[term.period] || term.period)
}

export const Term = ({term}) => {

	return (
			<div className="term">
				<div className="term-info">
					<div className="term-name">{term.name}</div>
					<div className={`term-billing-plan ${term.price ? '' : 'term-billing-plan--hidden'}`}>
						<div>{term.price && (<>
							<span>{PriceUtils.priceString(term.trial || term.price)}</span><span>{term.period ? `/${term.period}` : ''}</span></>)}
						</div>
						{term.trial && <div>{nextPeriods(term)}</div>}
					</div>
				</div>

				<div className="term-summary">
					<span className="term-description">{term.description || term.customData?.description || term.name}</span>
					<span className="price">{term.price ? PriceUtils.priceString(term.price) : ''}</span>
				</div>

				<div className="term-footer">
					<div className="stripe">
						<span>powered by</span>
						<img src="img/stripe.svg" alt="stripe"/>
					</div>
					<div className="divider"/>
					<div className="term-footer-links">
						<button className="term-footer-link">Terms</button>
						<button className="term-footer-link">Privacy</button>
					</div>
				</div>
			</div>
	)
}
